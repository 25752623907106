<template>
  <div class="modal-confirm">
    <div class="modal-confirm__body">
      <div class="modal-confirm__title">{{ title }}</div>
      <div class="modal-confirm__text">{{ text }}</div>
    </div>
    <div class="modal-confirm__footer">
      <div
        class="modal-confirm__btn"
        @click="dialogDismiss"
      >{{ btnCancelText }}</div>

      <div
        class="modal-confirm__btn"
        @click="dialogConfirm"
      >{{ btnConfirmText }}</div>

    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: String,
    text: String,
    resolve: {
      type: Function,
      default: () => false
    },
    btnConfirmText: {
      type: String,
      default: 'Подтвердить'
    },
    btnCancelText: {
      type: String,
      default: 'Отмена'
    }
  },
  methods: {
    dialogConfirm() {
      this.$emit('confirm');
      this.$emit('close');
      this.resolve(true);
    },
    dialogDismiss() {
      this.$emit('dismiss');
      this.$emit('close');
      this.resolve(false);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@style/src/component/modal-confirm-styles.scss";

</style>
